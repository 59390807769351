












































































































import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  name: 'SkillSection',
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    title: String,
    subTitle: String,
    descriptions: Array,
    image: Boolean,
  },
})
export default class SkillSection extends Vue {}
