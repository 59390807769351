





























































import Vue from 'vue';
import SkillSection from './SkillSection.vue';
import Component from 'vue-class-component';
import { DescriptionItem } from '../model/description-item';

@Component({
  components: { SkillSection },
})
export default class extends Vue {
  professional: DescriptionItem[] = [
    {
      title: `${this._format('2020/03/30')} - ...`,
      value: 'Advanced Software Developer @ Endare BVBA',
    },
    {
      title: `${this._format('2018/07/03')} - ${this._format('2020/03/27')}`,
      value: 'Frontend Developer @ Aztrix nv',
    },
  ];

  personal: DescriptionItem[] = [
    { title: 'Name', value: 'Nicolas Loots' },
    { title: 'Birthday', value: new Date('1997/02/14').toLocaleDateString() },
    { title: 'Nationality', value: 'Belgian' },
    { title: 'Residence', value: 'Hamme' },
    { title: 'Profession', value: this.professional[0].value },
  ];

  education: DescriptionItem[] = [
    {
      title: '2015 - 2018',
      value: 'Bachelor of Applied Computer Science @ Hogeschool Gent',
    },
    { title: '2010 - 2015', value: 'Latin-Science @ KOHa Sint-Jozef' },
  ];

  language: DescriptionItem[] = [
    { title: 'Dutch', value: 'Mother tongue' },
    { title: 'English', value: 'Fluent' },
    { title: 'German', value: 'Decent' },
    { title: 'French', value: 'Basic' },
  ];

  development: DescriptionItem[] = [
    {
      title: 'Front-end web',
      value: 'Angular, Vue.js, React',
    },
    { title: 'Back-end web', value: 'Node.js, PHP' },
    { title: 'Native apps', value: 'Ionic, Cordova, Capacitor' },
    { title: 'Scripting', value: 'Bash, PowerShell' },
    { title: 'Databases', value: 'MongoDB, MySQL, Microsoft SQL Server' },
    { title: 'Sysadmin', value: 'Docker, Bitrise' },
  ];

  private _format(date: string): string {
    return new Date(date).toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
    });
  }
}
